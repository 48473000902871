<template>
  <child-item :child='item'></child-item>
</template>

<script>

export default {
  components: {
    ChildItem: () => import('./child.vue')
  },
  props:['item']
}
</script>

<style>

</style>