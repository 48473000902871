<template>
  <div class="main-container" v-if="isShow">
    <b-card style="width: 400px" class="d-flex flex-column">
      <b-carousel
        id="carousel-1"
        :interval="3000"
        :controls="false"
        indicators
        :key="carrouselKey"
        style="text-shadow: 1px 1px 2px #333"
      >
        <b-carousel-slide
          v-for="(banner, i) in bannersByPage[currentPage]"
          :key="i"
          :img-src="banner.urlImage"
        >
        </b-carousel-slide>
      </b-carousel>
      <button class="modal-header__close-button" @click="closeModal">
        Close
      </button>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["banners", "currentPage"],
  data() {
    return {
      closed: false,
      carrouselInterval: 6000,
      bannersByPage: {},
      carrouselKey: 0,
    };
  },
  watch: {
    banners: {
      handler: function handler() {
        this.closed = false
        this.bannersByPage = {};
        this.groupBanners();
        this.carrouselKey = this.carrouselKey + 1;
      },
      deep: false,
    },
  },
  computed: {
    isShow() {
      return (
        !this.closed &&
        this.bannersByPage[this.currentPage] &&
        this.bannersByPage[this.currentPage].length > 0
      );
    },
  },
  created() {
    this.groupBanners();
    console.log(this.bannersByPage);
    console.log(this.bannersByPage[this.currentPage]);
    console.log(this.currentPage);
  },
  methods: {
    groupBanners() {
      this.banners.forEach((banner) => {

        if (!banner.pages) {
          return
        }

        banner.pages.forEach((path) => {
          if (!this.bannersByPage[path]) {
            this.bannersByPage[path] = [banner];
          } else {
            this.bannersByPage[path].push(banner);
          }
        });
      });
    },
    updateCarrouselInterval(item) {
      this.carrouselInterval = this.banners[item].length * 6000;
    },
    closeModal() {
      this.closed = true;
    },
    openModal() {
      this.closed = false;
    },
  },
};
</script>

<style scoped>
.modal-header__close-button {
  padding: 5px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 1.3rem;
  color: black;
}
.modal-header {
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal-header__close-button:hover {
  transition: 0.2s;
  background-color: rgba(50, 50, 50);
}
.container-modal {
  height: 100%;
}
.modal-body {
  background-color: black;
  width: 100%;
  height: 100%;
}
.modal {
  max-width: 40rem;
  padding: 5px;
  background-color: rgb(250, 250, 250);
  border-radius: 8px;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.4);
  margin: auto;
}

.main-container {
  padding: 2rem;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  top: 50px;
  place-items: center;
  z-index: 99;
}
#itemCarousel-container {
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}
</style>
