var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"footer",staticClass:"columns-wrapper"},[_vm._l((_vm.footer.sections),function(preview){return _c('span',{key:preview.id,staticClass:"d-md-flex d-sm-flex"},_vm._l((preview.columns),function(column){return _c('div',{key:column.id,staticStyle:{"inline-size":"-webkit-fill-available"}},[_c('div',{staticClass:"p-0",style:({ width: column.style.fill ? '100%' : 'auto' })},[_c('div',{staticClass:"column-item h-100 d-flex flex-column justify-content-center",staticStyle:{"max-height":"150px"}},[_c('div',{staticClass:"item-content h-100",style:({
              background: column.style.background,
              padding: column.style.padding + 'px',
            })},[_c('div',{staticClass:"element-wrapper h-100"},[_c('div',{staticClass:"h-100 d-flex justify-content-center align-items-start"},[_c('span',{staticClass:"w-100"},_vm._l((column.elements),function(element){return _c('div',{key:element.id,staticClass:"w-100 pl-5 pl-md-0"},[_c('div',{staticClass:"element-item-wrapper"},[_c('div',{staticClass:"widget-wrapper"},[_c('div',{class:element.name == 'Heading'
                              ? 'Heading-wrapper'
                              : element.name == 'Text Link'
                              ? 'text-widget-wrapper'
                              : element.name == 'List'
                              ? 'list-widget-wrapper d-flex justify-content-center'
                              : element.name == 'Text Editor'
                              ? 'text-widget-wrapper'
                              : element.name == 'Button'
                              ? 'button-widget-wrapper d-flex justify-content-center'
                              : element.name == 'Image'
                              ? 'text-widget-wrapper d-flex justify-content-center'
                              : element.name == 'Spacer'
                              ? ''
                              : ''},[(element.name == 'Heading')?_c('h1',{style:({
                              'font-size':
                                element.content.style.fontSize + 'px',
                              'font-weight': element.content.style.fontWeight,
                              'text-align': element.content.style.textAlign,
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              color: element.content.style.color,
                              background: element.content.style.background,
                              margin: element.content.style.margin + 'px',
                              padding: element.content.style.padding + 'px',
                              visibility: 'visible',
                              height: 'auto',
                            })},[_vm._v(" "+_vm._s(element.content.text)+" ")]):_vm._e(),(element.name == 'Text Link')?_c('a',{style:({
                              'font-size':
                                element.content.style.fontSize + 'px',
                              'font-weight': element.content.style.fontWeight,
                              'text-align': element.content.style.textAlign,
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              color: element.content.style.color,
                              background: element.content.style.background,
                              margin: element.content.style.margin + 'px',
                              padding: element.content.style.padding + 'px',
                              visibility: 'visible',
                              height: 'auto',
                            }),attrs:{"href":element.content.link}},[_vm._v(_vm._s(element.content.text))]):_vm._e(),(element.name == 'List')?_c('ul',{style:({
                              'font-size':
                                element.content.style.fontSize + 'px',
                              'font-weight': element.content.style.fontWeight,
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              padding:
                                element.content.style.padding == '0'
                                  ? ''
                                  : element.content.style.padding + 'px',
                              margin: element.content.style.margin + 'px',
                              color: element.content.style.color,
                              background: element.content.style.background,
                            })},_vm._l((element.content.list),function(list){return _c('li',{key:list.id,style:({
                                'text-align': element.content.style.textAlign,
                              })},[_c('div',{staticStyle:{"visibility":"visible"}},[_vm._v(" "+_vm._s(list.text)+" ")])])}),0):_vm._e(),(element.name == 'Text Editor')?_c('p',{style:({
                              'font-size':
                                element.content.style.fontSize + 'px',
                              'font-weight': element.content.style.fontWeight,
                              'text-align': element.content.style.textAlign,
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              color: element.content.style.color,
                              background: element.content.style.background,
                              margin: element.content.style.margin + 'px',
                              padding: element.content.style.padding + 'px',
                              visibility: 'visible',
                              height: 'auto',
                            })},[_vm._v(" "+_vm._s(element.content.text)+" ")]):_vm._e(),(element.name == 'Button')?_c('button',{style:({
                              'font-size':
                                element.content.style.fontSize + 'px',
                              'font-weight': element.content.style.fontWeight,
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              color: element.content.style.color,
                              background: element.content.style.background,
                              padding: element.content.style.padding + 'px',
                              margin: element.content.style.margin + 'px',
                            })},[_vm._v(" "+_vm._s(element.content.text)+" ")]):_vm._e(),(element.name == 'Image')?_c('img',{style:({
                              'border-radius':
                                element.content.style.borderRadius + 'px',
                              padding: element.content.style.padding + 'px',
                              margin: element.content.style.margin + 'px',
                            }),attrs:{"fluid":"","src":element.content.src,"alt":"","width":element.content.style.width}}):_vm._e(),(element.name == 'Spacer')?_c('div',{style:({
                              background: element.content.style.background,
                              height: element.content.style.height,
                            })}):_vm._e()])])])])}),0)])])])])])])}),0)}),_c('div',{staticStyle:{"max-width":"100vw"},style:({
      backgroundColor:
        _vm.footer.sections[0].columns[0].style.background || 'white',
    })},[_c('div',{staticClass:"flex flex-wrap justify-around pa-4 w-full"},[_c('div',{staticClass:"pa-4 w-full md:w-auto text-center"},[_c('a',{staticClass:"textDecorationNone",on:{"click":function($event){return _vm.ModaltermsConditions()}}},[_vm._v(" T&C ")])]),_c('div',{staticClass:"pa-4 w-full md:w-auto text-center"},[_c('a',{staticClass:"textDecorationNone",on:{"click":function($event){return _vm.ModalpoliticsPrivacy()}}},[_vm._v(" P&P ")])]),_c('div',{staticClass:"pa-4 w-full md:w-auto text-center"},[_c('a',{staticClass:"textDecorationNone",on:{"click":function($event){return _vm.ModalgameRes()}}},[_vm._v(" GR ")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }