<template>
  <b-img-lazy
    blank-src=""
    blankColor="#01010145"
    :width="imgWidth"
    :height="imgHeight"
    :src="getImgPath"
    alt="home-page-image"
  />
</template>

<script>
import i18n from '@/libs/i18n'

export default {
  name: "MultiLangImg",
  props: ["all"],
  computed: {
    getImgPath() {
      const { imgPaths } = this.$attrs;
      console.log(imgPaths[i18n.locale]);
      return imgPaths[i18n.locale];
    },
    imgWidth() {
      if (this.all.size) {
        const dimensions = this.all.size.split(" x ");
        return parseInt(dimensions[0]);
      }
      return "";
    },
    imgHeight() {
      if (this.all.size) {
        const dimensions = this.all.size.split(" x ");
        return parseInt(dimensions[1]);
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
