<template>
  <div>
    <button
      class="btn-login px-2 border border-solid uppercase p-1 rounded-xl mr-1 ease-linear transition-all duration-500 shadow-md"
      type="button"
      style="font-size: 1em; text-transform: unset !important"
      :style="StyleBtn"
    >
      <span class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="width: 1.5em"
          viewBox="0 0 22 22"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Login</span>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["config"],
  computed: {
    color() {
      if (this.config) {
        return this.config.color ? this.config.color : "primary";
      }

      return "primary";
    },
    StyleSend() {
      return {
        color: this.config.text_color ? this.config.text_color : "white",
      };
    },
    StyleClose() {
      return {
        color: this.config.color ? this.config.color : "black",
      };
    },

    background() {
      if (this.config) {
        return this.config.text_color ? this.config.text_color : "";
      }

      return "";
    },

    StyleBtn() {
      return {
        "background-color": this.config.color ? this.config.color : "#039BE5",
        color: this.config.text_color ? this.config.text_color : "white",
        "border-opacity": "0.6",
        "border-color": this.config.color ? this.config.color : "#039BE5",
        "--background-color-hover": this.config.text_color
          ? this.config.text_color
          : "white",
        "--color-hover": this.config.color ? this.config.color : "#039BE5",
      };
    },
  },
};
</script>
<style scoped>
.btn-login:hover {
  background-color: var(--background-color-hover) !important;
  color: var(--color-hover) !important;
}
</style>
