export default {
    namespaced: true,
    state: {
        preview: 'general'
    },

    mutations: {
        SWITCH_PREVIEW(state, value) {
            state.preview = value
        }
    }
}