<script>
/* eslint-disable */
import Container from "@/views/components/whitelabel-templates/App/components/modules/HomePage/container.vue";
export default {
  name: "AppHomePage",
  components: { Container },
  props : ['homePage'],
  computed: {
    //We have refactored this code so that we do not have to delete it since it could be useful in the future
    homeStyle() {
      return {};
      const backgroundImage = this.homepage.background_image;
      const backgroundColor = this.homepage.background_color;
      if (backgroundImage) return { backgroundImage };
      else if (backgroundColor) return { backgroundColor };
      else return {};
    },
  },
  mounted() {
    this.$root.$on("highlightComponent", (e) => {
      if (e === "HomePageManagement") {
        this.$refs.homePage.style.transform = "scale(1.01)";
        this.$refs.homePage.style.transition = "all .3s ease-in-out";
      } else {
        this.$refs.homePage.style.transform = ""
        this.$refs.homePage.style.boxShadow = "none";
      }
    });
  }
};
</script>

<template>
  <div class="home" id="home" ref="homePage" :style="homeStyle">
    <container :content="homePage"></container>
  </div>
</template>

<style scoped lang="scss"></style>
