<template>
  <div class="mt-2 text-white" style="width: 115px">
      <div class="px-3 py-2 rounded-3xl text-center text-lg">{{ hours }}:{{minutes}}:{{seconds}}</div>
  </div>
</template>
<script>
export default {
  name: "Digital",
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    setInterval(() => this.setTime(), 1000)
  },
  methods: {
    setTime() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours;
      minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes;
      seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    }
  }
}
</script>
<style scoped>
.reloj {
  color: aliceblue;
  margin: 15 0 0 0;
}
</style>