<template>
  <div>
    <template>
      <button
        style="font-size: 1em; text-transform: unset !important"
        class="btn-register px-2 border border-solid uppercase p-1 rounded-xl mr-1 ease-linear transition-all duration-500 shadow-md"
        type="button"
        :style="StyleBtn"
      >
        <span class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="width: 1.5em"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Crear cuenta</span>
        </span>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: ["config", "type", "currencies"],
  computed: {
    color() {
      if (this.config) {
        return this.config.color ? this.config.color : "primary";
      }
      return "primary";
    },
    StyleSend() {
      return {
        color: this.config.text_color ? this.config.text_color : "white",
      };
    },
    StyleClose() {
      return {
        color: this.config.color ? this.config.color : "black",
      };
    },
    background() {
      if (this.config) {
        return this.config.text_color ? this.config.text_color : "";
      }

      return "";
    },
    StyleBtn() {
      if (this.config) {
        return {
          "background-color": this.config.text_color
            ? this.config.text_color
            : "#039BE5",
          color: this.config.color ? this.config.color : "white",
          "border-opacity": "0.5",
          "border-color": this.config.text_color
            ? this.config.text_color
            : "#039BE5",
          "--background-color-hover": this.config.color
            ? this.config.color
            : "white",
          "--color-hover": this.config.text_color
            ? this.config.text_color
            : "#039BE5",
        };
      }

      return {
        "background-color": "#039BE5",
        color: "white",
        "border-opacity": "0.5",
        "border-color": "#039BE5",
        "--background-color-hover": "white",
        "--color-hover": "#039BE5",
      };
    },
  },
};
</script>
<style>
.v-dialog .register {
  overflow-y: hidden !important;
}
.btn-register:hover {
  background-color: var(--background-color-hover) !important;
  color: var(--color-hover) !important;
}
</style>
