<template>
  <div class="columns-wrapper" ref="footer">
    <span
      class="d-md-flex d-sm-flex"
      v-for="preview in footer.sections"
      :key="preview.id"
    >
      <div
        v-for="column in preview.columns"
        :key="column.id"
        style="inline-size: -webkit-fill-available"
      >
        <div
          class="p-0"
          :style="{ width: column.style.fill ? '100%' : 'auto' }"
        >
          <div
            class="column-item h-100 d-flex flex-column justify-content-center"
            style="max-height: 150px"
          >
            <div
              class="item-content h-100"
              :style="{
                background: column.style.background,
                padding: column.style.padding + 'px',
              }"
            >
              <div class="element-wrapper h-100">
                <div
                  class="h-100 d-flex justify-content-center align-items-start"
                >
                  <span class="w-100">
                    <div
                      class="w-100 pl-5 pl-md-0"
                      v-for="element in column.elements"
                      :key="element.id"
                    >
                      <div class="element-item-wrapper">
                        <div class="widget-wrapper">
                          <div
                            :class="
                              element.name == 'Heading'
                                ? 'Heading-wrapper'
                                : element.name == 'Text Link'
                                ? 'text-widget-wrapper'
                                : element.name == 'List'
                                ? 'list-widget-wrapper d-flex justify-content-center'
                                : element.name == 'Text Editor'
                                ? 'text-widget-wrapper'
                                : element.name == 'Button'
                                ? 'button-widget-wrapper d-flex justify-content-center'
                                : element.name == 'Image'
                                ? 'text-widget-wrapper d-flex justify-content-center'
                                : element.name == 'Spacer'
                                ? ''
                                : ''
                            "
                          >
                            <h1
                              v-if="element.name == 'Heading'"
                              :style="{
                                'font-size':
                                  element.content.style.fontSize + 'px',
                                'font-weight': element.content.style.fontWeight,
                                'text-align': element.content.style.textAlign,
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                color: element.content.style.color,
                                background: element.content.style.background,
                                margin: element.content.style.margin + 'px',
                                padding: element.content.style.padding + 'px',
                                visibility: 'visible',
                                height: 'auto',
                              }"
                            >
                              {{ element.content.text }}
                            </h1>
                            <a
                              v-if="element.name == 'Text Link'"
                              :href="element.content.link"
                              :style="{
                                'font-size':
                                  element.content.style.fontSize + 'px',
                                'font-weight': element.content.style.fontWeight,
                                'text-align': element.content.style.textAlign,
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                color: element.content.style.color,
                                background: element.content.style.background,
                                margin: element.content.style.margin + 'px',
                                padding: element.content.style.padding + 'px',
                                visibility: 'visible',
                                height: 'auto',
                              }"
                              >{{ element.content.text }}</a
                            >

                            <ul
                              v-if="element.name == 'List'"
                              :style="{
                                'font-size':
                                  element.content.style.fontSize + 'px',
                                'font-weight': element.content.style.fontWeight,
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                padding:
                                  element.content.style.padding == '0'
                                    ? ''
                                    : element.content.style.padding + 'px',
                                margin: element.content.style.margin + 'px',
                                color: element.content.style.color,
                                background: element.content.style.background,
                              }"
                            >
                              <li
                                v-for="list in element.content.list"
                                :key="list.id"
                                :style="{
                                  'text-align': element.content.style.textAlign,
                                }"
                              >
                                <div style="visibility: visible">
                                  {{ list.text }}
                                </div>
                              </li>
                            </ul>

                            <p
                              v-if="element.name == 'Text Editor'"
                              :style="{
                                'font-size':
                                  element.content.style.fontSize + 'px',
                                'font-weight': element.content.style.fontWeight,
                                'text-align': element.content.style.textAlign,
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                color: element.content.style.color,
                                background: element.content.style.background,
                                margin: element.content.style.margin + 'px',
                                padding: element.content.style.padding + 'px',
                                visibility: 'visible',
                                height: 'auto',
                              }"
                            >
                              {{ element.content.text }}
                            </p>
                            <button
                              v-if="element.name == 'Button'"
                              :style="{
                                'font-size':
                                  element.content.style.fontSize + 'px',
                                'font-weight': element.content.style.fontWeight,
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                color: element.content.style.color,
                                background: element.content.style.background,
                                padding: element.content.style.padding + 'px',
                                margin: element.content.style.margin + 'px',
                              }"
                            >
                              {{ element.content.text }}
                            </button>

                            <img
                              fluid
                              v-if="element.name == 'Image'"
                              :src="element.content.src"
                              alt=""
                              :width="element.content.style.width"
                              :style="{
                                'border-radius':
                                  element.content.style.borderRadius + 'px',
                                padding: element.content.style.padding + 'px',
                                margin: element.content.style.margin + 'px',
                              }"
                            />

                            <div
                              v-if="element.name == 'Spacer'"
                              :style="{
                                background: element.content.style.background,
                                height: element.content.style.height,
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>

    <div
      class=""
      style="max-width: 100vw"
      :style="{
        backgroundColor:
          footer.sections[0].columns[0].style.background || 'white',
      }"
    >
      <div class="flex flex-wrap justify-around pa-4 w-full">
        <div class="pa-4 w-full md:w-auto text-center">
          <a class="textDecorationNone" @click="ModaltermsConditions()">
            T&C
          </a>
        </div>
        <div class="pa-4 w-full md:w-auto text-center">
          <a class="textDecorationNone" @click="ModalpoliticsPrivacy()">
            P&P
          </a>
        </div>
        <div class="pa-4 w-full md:w-auto text-center">
          <a class="textDecorationNone" @click="ModalgameRes()"> GR </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeCount: 10,
      modalExpire: false,
      termsConditions: false,
      textTermsConditions: "",
      termsConditionsLTL: "", //en | es | pl
      politicsPrivacy: false,
      textPoliticsPrivacy: "",
      politicsPrivacyLTL: "", //en | es | pl
      gameRes: false,
      textGameRes: "",
      gameResLTL: "", //en | es | pl
      loadingTextTermsConditions: false,
      loadingTextPoliticsPrivacy: false,
      loadingTextGameRes: false,
    };
  },
  props: {
    footer: {
      type: Object,
    },
  },
  methods: {
    noPoliticsPrivacy() {
      const politicsPrivacy = document.querySelector("section.politicsPrivacy");
      localStorage.setItem("politicsPrivacy", false);
      politicsPrivacy.style.display = "none";
    },
    yesPoliticsPrivacy() {
      const politicsPrivacy = document.querySelector("section.politicsPrivacy");
      localStorage.setItem("politicsPrivacy", true);
      politicsPrivacy.style.display = "none";
      const navmx0 = document.querySelector("nav.mx-0");
      navmx0.style.display = "block";
      const isCookie = localStorage.getItem("isCookie");
      if (isCookie == "false") {
        const navmx0 = document.querySelector("nav.mx-0");
        navmx0.style.display = "none";
      }
    },
    noCookie() {
      const seccionCookie = document.querySelector("section.cookies");
      localStorage.setItem("isCookie", false);
      seccionCookie.style.display = "none";
    },
    yesCookie() {
      const seccionCookie = document.querySelector("section.cookies");
      localStorage.setItem("isCookie", true);
      seccionCookie.style.display = "none";
      const navmx0 = document.querySelector("nav.mx-0");
      navmx0.style.display = "block";
      const politicsPrivacy = localStorage.getItem("politicsPrivacy");
      if (politicsPrivacy == "false") {
        const navmx0 = document.querySelector("nav.mx-0");
        navmx0.style.display = "none";
      }
    },
    ModaltermsConditions() {
      this.handleDialogs(
        "termsConditions",
        "loadingTextTermsConditions",
        "textTermsConditions",
        "termsConditions"
      );
    },
    ModalpoliticsPrivacy() {
      this.handleDialogs(
        "politicsPrivacy",
        "loadingTextPoliticsPrivacy",
        "textPoliticsPrivacy",
        "politicsPrivacy"
      );
    },
    ModalgameRes() {
      this.handleDialogs(
        "gameRes",
        "loadingTextGameRes",
        "textGameRes",
        "gameRes"
      );
    },
    politicCookies() {
      window.location.href = "/politicCookies";
    },
    async handleDialogs(
      dialogHandle,
      loadingHandle,
      textHandle,
      propertyToTranslate
    ) {
      const whiteLabel = getwhiteLabelData();
      try {
        this[dialogHandle] = true;
        //if it was translated once it will not be translated again
        if (this[dialogHandle + "LTL"] === i18n.locale) return;
        this[loadingHandle] = true;
        //temporal comment
        // this[textHandle] = await translatableText(whiteLabel[propertyToTranslate], false, true)
        this[textHandle] = whiteLabel[propertyToTranslate];
        this[dialogHandle + "LTL"] = i18n.locale; //set the LTS (Last Translated Language)
      } catch (e) {
        this[textHandle] = whiteLabel[propertyToTranslate];
      } finally {
        this[loadingHandle] = false;
      }
    },
  },
  mounted() {
    this.$root.$on("highlightComponent", (e) => {
      if (e === "Footer") {
        this.$refs.footer.style.transform = "scale(1.01)";
        this.$refs.footer.style.transition = "all .3s ease-in-out";
      } else {
        this.$refs.footer.style.transform = "";
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  padding: 0px 0px;
  background: #fff;
}
</style>
<style>
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00082c;
  color: white;
  font-family: arial;
  text-align: center;
  z-index: 1;
}
.cookies__boton {
  background: initial;
  border: 2px solid white;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  width: 5rem;
  text-align: center;
}
.cookies__boton2 {
  background: initial;
  border: 2px solid white;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  width: 15rem;
  text-align: center;
}
</style>
<style>
.columns-wrapper {
  background-color: #050550;
}
.background-sectionStatic {
  background-color: #050550;
  color: #fff;
}
a.textDecorationNone {
  color: #fff !important;
  text-decoration: none;
}
.politicCookies {
  color: #fff !important;
  text-decoration: none !important;
}
.timeCount {
  font-weight: bold;
  color: blue;
  font-size: 32px;
}
</style>
