<template>
  <div class="w-full flex flex-col " v-show="visible">
    <template v-for="(layout,index) in content.layout" style="background-color: blue">
      <component v-bind:is="layout.el" v-bind="layout.props" :key="index" v-reset-padding>
        <template v-for="(child, i) in layout.child">
          <child :child='child' :key="i"></child>
        </template>
      </component>
    </template>
  </div>
</template>

<script>
import Child from './child.vue'
import {BCol, BContainer, BRow} from 'bootstrap-vue'

export default {
  components:
      {
        Child,
        BContainer,
        BRow,
        BCol
      },
  data: () => ({
    model: 0,
    visible: false,
  }),
  directives: {
    'reset-padding': {
      inserted: function (el) {
        el.classList.remove('pt-2');
      }
    }
  },
  props: ['content'],
  mounted() {
    // console.log(this.content)
    this.$nextTick(() => {
      setTimeout(() => {
        this.visible = true
      }, 500)
    })
  }
}
</script>

<style scoped>
.container-fluid {
  z-index: auto !important;
  padding-top: 0 !important;
}

</style>