<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    toggle-class="dropdown-language"
    right
  >
    <template #button-content>
      <div class="d-flex flag-content align-items-center">
        <b-img
          :src="require('../../../assets' + currentLocale.img)"
          width="35px"
          :alt="currentLocale.locale"
        />
        <span style="font-size: 1em" class="ml-50 ml-1 text-white">{{
          currentLocale.name
        }}</span>
      </div>
    </template>
        <b-dropdown-item
          class="ma-2"
          v-for="localeObj in locales"
          :key="localeObj.locale"
        >
          <div class="d-flex">
            <b-img :src="require('../../../assets' + localeObj.img)" width="30px" :alt="localeObj.locale" />
            <span class="ml-50 ml-1">{{ localeObj.name }}</span>
          </div>
        </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import i18n from "@/libs/i18n";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales: [
        /* eslint-disable global-require */
        {
          locale: "es",
          img: "/img/flags/Spain.svg",
          name: "Español",
        },
        {
          locale: "en",
          img: "/img/flags/USA.svg",
          name: "English",
        },
        /* eslint-disable global-require */
      ],
    };
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === i18n.locale);
    },
  },

  methods: {
    handleSetLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>

<style>
.nav-item,
.b-nav-dropdown,
.dropdown,
.dropdown-language {
  padding: 0px !important;
}
#dropdown-grouped__BV_toggle_::after {
  content: none !important;
}
#dropdown-grouped__BV_toggle_::marker {
  content: none !important;
}
.dropdown-language::marker {
  content: none !important;
}
</style>
